<template>
  <!-- Main navbar -->
  <header class="relative z-30 font-display text-white">
    <nav class="border-b-4 border-brand-500 bg-black" aria-label="Top">
      <div class="flex h-16 items-center justify-between">
        <!-- Mobile hamburger (xl-) -->
        <div class="xl:hidden">
          <button
            class="p-2"
            @click="openNav()"
          >
            <span class="sr-only">Open menu</span>
            <i-heroicons-solid:menu class="h-6 w-6" />
          </button>
        </div>
        <div class="mx-auto flex max-w-6xl flex-1 items-center justify-center gap-8 sm:px-4 xl:justify-start">
          <!-- Logo -->
          <div>
            <router-link to="/">
              <cgn-organisation-logo class="max-h-10 w-auto" />
            </router-link>
          </div>

          <!-- Desktop links (xl+) -->
          <div class="hidden flex-1 space-x-8 text-sm font-bold uppercase xl:flex">
            <div v-for="link in menus" :key="link.name" class="flex items-center">
              <cgn-navbar-mega-menu
                v-if="link.identifier == 'megamenu'"
                class="hover:text-brand-500"
                :menu="link"
              />
              <cgn-navbar-dropdown
                v-else-if="link.identifier == 'dropdown'"
                class="hover:text-brand-500"
                :menu="link"
              />
              <router-link
                v-else
                :to="link.link"
                class="py-2 hover:text-brand-500"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </div>
        <!-- Right blocks (xl+) -->
        <cgn-navbar-cart class="mr-2 sm:mr-4" />
        <cgn-navbar-profile-button class="mx-2 mr-4 hidden xl:block" />
        <!-- Tickets link -->
        <router-link to="/events" class="relative ml-5 flex h-full items-center bg-brand-500 pr-1 text-sm font-bold uppercase sm:px-4 sm:text-lg">
          <div class="absolute left-0 -ml-5 h-full w-16 skew-x-[-30deg] bg-brand-500" />
          <div class="z-10 sm:pl-2 sm:pr-6">
            <i-fa6-solid:arrow-up-right-from-square class="mr-1 inline sm:mr-2 sm:h-6 sm:w-6" />
            Get tickets
          </div>
        </router-link>
      </div>
    </nav>
  </header>

  <!-- Mobile nav menu (xl-) -->
  <div v-show="!navParentIsHidden" class="fixed inset-0 z-40 flex font-display xl:hidden">
    <!-- Background overlay -->
    <transition
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      class="transition-opacity duration-300 ease-linear"
      @after-leave="navParentIsHidden = true"
    >
      <div v-if="!navIsHidden" class="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" @click="closeNav()" />
    </transition>

    <!-- Navigation card -->
    <transition
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
      class="transition duration-300 ease-in-out"
    >
      <div v-show="!navIsHidden" class="relative flex w-full max-w-xs flex-col overflow-y-scroll bg-white shadow-md">
        <!-- Card logo bar -->
        <div class="border-b-4 border-brand-500 bg-black">
          <div class="flex h-16 items-center justify-between">
            <div class="flex flex-1 items-center justify-between gap-8 px-8">
              <!-- Logo -->
              <div>
                <router-link v-if="isDark" to="/">
                  <cgn-lazy-image class="h-10 w-auto" :image="darkLogo" />
                </router-link>
                <router-link v-else to="/">
                  <cgn-lazy-image class="h-10 w-auto" :image="lightLogo" />
                </router-link>
              </div>
            </div>
            <!-- Red Color Block -->
            <div class="relative ml-4 flex h-full cursor-pointer items-center bg-brand-500 px-2 text-sm font-bold uppercase sm:text-lg" @click="closeNav()">
              <div class="absolute left-0 -ml-5 h-full w-14 -skew-x-[30deg] bg-brand-500" />
              <div class="z-10 w-10 pt-1">
                <i-heroicons-solid:x class="h-8 w-8 text-white" />
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile links -->
        <div class="space-y-2 p-4 font-bold uppercase text-gray-600">
          <div v-for="link in menus" :key="link.name" class="flow-root">
            <cgn-navbar-dropdown-mobile
              v-if="link.children.length > 0"
              :heading="link.name"
            >
              <span v-for="childLink in link.children" :key="childLink.name">
                <cgn-navbar-dropdown-mobile
                  v-if="childLink.children.length > 0"
                  :heading="childLink.name"
                >
                  <span v-for="subChildLink in childLink.children" :key="subChildLink.name">
                    <router-link
                      :to="subChildLink.link"
                      class="block py-2"
                    >
                      {{ subChildLink.name }}
                    </router-link>
                  </span>
                </cgn-navbar-dropdown-mobile>
                <router-link
                  v-else
                  :to="childLink.link"
                  class="block py-2"
                >
                  {{ childLink.name }}
                </router-link>
              </span>
            </cgn-navbar-dropdown-mobile>
            <router-link
              v-else
              :to="link.link"
              class="block py-2"
            >
              {{ link.name }}
            </router-link>
          </div>
        </div>
        <div class="flex h-full flex-row items-end justify-evenly">
          <cgn-navbar-profile-button />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const navIsHidden = ref(true)
const navParentIsHidden = ref(true)
function openNav() {
  navParentIsHidden.value = false
  navIsHidden.value = false
}
function closeNav() {
  navIsHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  closeNav()
})

const menus = ref<CognitoMenu[]>([])

async function loadMenu() {
  menus.value = await new CognitoMenu().getMenu('Main Menu', '16x3', '1920')
}

onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>
