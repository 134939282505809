<template>
  <footer aria-labelledby="footer-heading" class="bg-white">
    <div class="bg-black border-t-4 border-brand-500">
      <div class="h-16 flex justify-between items-center">
        <!-- Red Color Block -->
        <div class="bg-brand-500 h-full uppercase font-bold text-sm sm:text-lg flex items-center relative px-2 sm:px-4 mr-4">
          <div class="h-full bg-brand-500 -skew-x-[30deg] absolute right-0 w-16 -mr-5" />
          <div class="sm:pl-2 sm:pr-6 w-6 sm:w-20" />
        </div>
        <div class="flex flex-1 items-center justify-between gap-8 px-8">
          <!-- Logo -->
          <div>
            <router-link to="/">
              <cgn-organisation-logo class="h-10 w-auto" />
            </router-link>
          </div>

          <!-- Social media links (sm+) -->
          <div class="hidden sm:flex gap-2">
            <a v-if="instagramLink" :href="instagramLink" class="block rounded-md bg-white p-2">
              <i-fa:instagram />
            </a>
            <a v-if="facebookLink" :href="facebookLink" class="block rounded-md bg-white p-2">
              <i-fa:facebook />
            </a>
          </div>
        </div>
      </div>
    </div>
    <h3 id="footer-heading" class="sr-only">
      Footer
    </h3>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="py-20">
        <div
          class="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-y-0 md:divide-x-2 divide-gray-300 md:gap-x-8 md:gap-y-6"
        >
          <div class="flex flex-col divide-y-2 divide-gray-300 gap-y-6">
            <!-- Footer links block -->
            <div class="flex flex-col gap-2">
              <div class="gap-2 grid grid-cols-2 font-bold text-gray-600">
                <div v-for="link in menus" :key="link.name" class="flex items-center">
                  <router-link
                    :to="link.link"
                    class="hover:text-brand-500"
                  >
                    {{ link.name }}
                  </router-link>
                </div>
              </div>
              <cgn-button color-brand class="uppercase font-bold text-sm sm:text-lg" url="/events">
                <i-fa6-solid:arrow-up-right-from-square class="inline sm:w-6 sm:h-6 mr-1 sm:mr-2" />
                Get tickets
              </cgn-button>
            </div>
            <div>
              <!-- Payment options block -->
              <cgn-lazy-image image-hash="VyK1" image-aspect="16x3W" image-width="500" />
            </div>
          </div>
          <div class="col-span-2">
            <!-- Footer gallery -->
            <div class="p-2 flex flex-wrap max-w-lg lg:ml-10 mx-auto">
              <div v-for="slide in gallery.slides" :key="slide.id" class="group bg-black overflow-hidden relative w-1/3 mx-auto">
                <cgn-lazy-image :image="slide.image" class="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-2 text-center">
        <p class="text-sm text-gray-500">
          &copy; Copyright {{ year }} Roll Racing Australia
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'
import { CognitoGallery } from '~cognito/models/Cognito/Gallery'

const facebookLink = computed(() => {
  return usePagesStore().currentDomain.facebook_link
})
const instagramLink = computed(() => {
  return usePagesStore().currentDomain.instagram_link
})

const year = ref(new Date().getFullYear())

const menus = ref<CognitoMenu[]>([])
async function loadMenu() {
  menus.value = await new CognitoMenu().getMenu('Footer Menu', '16x3', '1920')
}

const gallery = ref(new CognitoGallery())
async function loadGallery() {
  const data = await new CognitoGallery().find_one({
    url: 'footer-gallery',
    image_aspect: '1x1W',
    image_width: 300,
  })
  gallery.value = data
}

onMounted(() => {
  loadMenu()
  loadGallery()
})

onServerPrefetch(async () => {
  await loadMenu()
  await loadGallery()
})
</script>
